import React from 'react'
import { Route, Routes } from "react-router-dom";
import Quotations from '../../Components/Customer/Quotations/Quotations'
import Quotation from '../../Components/Customer/Quotations/Quotation';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterQuotations(props) {

    

    return (
        <Routes>
            <Route path=""      element={<Quotations {...props} />} />
            <Route path=":id"   element={<Quotation {...props} />} />
        </Routes>
    )
}

export default RouterQuotations;
