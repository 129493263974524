import React, { useEffect, useState } from "react";
import _ from "lodash"
import { useContext } from "react";
import User from "./Logged";

/**
 *
 *
 * @param {*} permisosUsuario
 * @param {*} permissionsObject
 * @return {*} 
 *
 * @method usePermissions 
 * @description Se le envian los objectos con el arreglo donde se encuentra posicionado el permiso a revisar.   
 */
function usePermissions(permisosUsuario, permissionsObject) {
    
    let user = useContext(User)
    if (permisosUsuario == null)
        permisosUsuario = user?.rol_id?.permisos
    
    const [permissionsList, setPermissionsList] = useState({});
    const [permisosUsuarioHook, setPermissionsHook] = useState({});
    const [permissionsObjectHook, setPermissionsObjectHook] = useState({});
    
    useEffect(() => {
        if (!_.isEqual(permisosUsuario, permisosUsuarioHook) || !_.isEqual(permissionsObject, permissionsObjectHook)) {
            let permissionListTemp = {}

            for (const permissionsObjectKey in permissionsObject) {

                try {
                    let tempPermission = permisosUsuario
                    for (const iterator of permissionsObject[permissionsObjectKey])
                        tempPermission = tempPermission[iterator]
                    permissionListTemp[permissionsObjectKey] = tempPermission
                } catch (error) {
                    permissionListTemp[permissionsObjectKey] = false
                }
            }
            setPermissionsHook(permisosUsuario)
            setPermissionsObjectHook(permissionsObject)
            setPermissionsList(permissionListTemp)
        }
    });


    return permissionsList;
}

export default usePermissions


export {
    usePermissions
}