import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin } from 'antd';

import axios from "axios"
import { getResponseError } from "../../Utils";

const { Title } = Typography;
const { Option } = Select;


class ModalSizes extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.size_id !== undefined) {
            this.get()
        }
    }

    /**
     * @memberof ModalSizes
     * @method get
     * @description Obtiene un Tamaño de la DB
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/size/' + this.props.size_id).then(response => {
            let size = response.data;
            this.formRef.current.setFieldsValue({
                nombre: size.nombre,
                descripcion: size.descripcion
            })
        }).catch(error => {
            message.error('Error al obtener el tamaño')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalSizes
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if (this.props.size_id) {
            this.update(values)
        } else {
            this.add(values)
        }

    }

    /**
     * @memberof ModalSizes
     * @method add
     * @description Añade una size a la BD
     *
     */
    add = (values) => {
        axios.post('/sizes', values).then(response => {
            message.success('Tamaño creado!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al crear el tamaño '))
            console.log(error)
        })
    }

    /**
     * @memberof ModalSizes
     * @method update
     * @description Actualiza la información de un Tamaño
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/sizes', {
            ...values,
            size_id: this.props.size_id
        }).then(response => {
            message.success('Tamaño actualizado!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error actualizando tamaño'))
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-size"
                id="form-size"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, size_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={size_id ? "Editar Tamaño" : "Crear Tamaño"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-size', key: 'submit', htmlType: 'submit' }}
    >
        <ModalSizes {...props} />
    </Modal>

}