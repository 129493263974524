import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, Layout, Space, message, Card, List, Breadcrumb } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import Avatar from '../../Widgets/Avatar/Avatar';

//componentes
import ModalMovimientos from "./ModalMovimientos"
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import usePermissions from '../../../Hooks/usePermissions';
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';



const { Content } = Layout;
const { Text, Title } = Typography
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Movimientos
 * @extends {Component}
 * @description Vista donde se listan todos los productos
 */
class Movimientos extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            inventario_id: this.props.params.inventario_id,
            inventario: {},
            movimientos: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {

        if (this.props.params.inventario_id) {
            this.getInventario()
            this.getMovimientos()
        }

    }

    /**
    * @method getInventario
    * @description Obtener al producto
    */
    getInventario = () => {
        axios.get('/inventario/' + this.props.params.inventario_id)
            .then(({ data }) => {
                this.setState({ inventario: data })
            }).catch(error => {
                message.error('Error al obtener el producto')
            })
    }

    /**
    *
    * @memberof Movimientos
    * @method getMovimientos
    * @description Obtiene una lista de las movimientos del producto
    */
    getMovimientos = async ({
        page = this.state.movimientos.page,
        limit = this.state.movimientos.limit,
        search = this.props.search,
    } = this.state.movimientos) => {

        this.setState({ loading: true })
        axios.get('/movimientos', {
            params: {
                limit,
                page,
                search,
                inventario_id: this.props.params.inventario_id
            }
        })
            .then(({ data }) => {
                console.clear()
                console.log("data", data);
                this.setState({ movimientos: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        let { inventario } = this.state;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title={<Breadcrumb
                            className="breadcrumb-header"
                            items={[
                                {
                                    title: "Inventario",
                                    onClick: () => this.props.navigate('/admin/inventario'),
                                    className: "link"
                                },
                                {
                                    title: `Movimientos de ${inventario.producto_id?.nombre}`,
                                },
                            ]}
                        />}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin registros" }}
                            dataSource={this.state.movimientos.data}
                            pagination={{
                                current: this.state.movimientos.page,
                                pageSize: this.state.movimientos.limit,
                                total: this.state.movimientos.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getMovimientos({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col span={12} className="center">
                                    <Text strong>Producto</Text>
                                </Col>
                                {/* <Col span={6} className="center">
                                    <Text strong>Variant</Text>
                                </Col> */}
                                <Col span={6} className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} className="center">
                                                <Text ellipsis>{item.producto_id?.nombre}</Text>
                                            </Col>
                                            {/* <Col xs={6} className="center">
                                                <Text ellipsis>{item.variante_id?.nombre}</Text>
                                            </Col> */}
                                            <Col xs={6} className="center">
                                                <Text>{item.tipo === 1 ? <ArrowUpOutlined style={{ color: "#52c41a", fontSize: "22px" }} /> : <ArrowDownOutlined style={{ color: "#ff4d4f", fontSize: "22px" }} />}</Text>
                                                <Text className="ml-1" ellipsis>{item.cantidad.toMoney(true)}</Text>
                                            </Col>
                                            <Col xs={6} className="center">
                                                <Text ellipsis>{moment(item.createdAt).format("MM/DD/YYYY HH:mm a")}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visble: true })} />
                    </>}

                    <ModalMovimientos
                        open={this.state.modal_visble}
                        inventario_id={this.props.params.inventario_id}
                        movimiento_id={this.state.movimiento_id}
                        onClose={(flag) => {
                            this.setState({ modal_visble: false, movimiento_id: undefined })
                            if (flag === true)
                                this.getMovimientos()
                        }}
                    />

                </Spin>
            </>
        )
    }
}

export default function (props) {

    const params = useParams()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ['inventario', 'movimientos', 'create']
    })

    return <Movimientos {...props} {...permisos} navigate={useNavigate()} params={params} />
}