import { PlusOutlined } from '@ant-design/icons';
import {
    List,
    message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import User from '../../../../../Hooks/Logged';
import usePermissions from '../../../../../Hooks/usePermissions';
import useSearch from '../../../../../Hooks/useSearch';


import '../../../../../Styles/Modules/Admin/clientes.scss';
import { CardAddress } from '../../../../Widgets/Cards';
import ModalDirecciones from './ModalDirecciones';


/**
 * @class Direcciones
 * @extends {Component}
 * @description Vista donde se listan las direcciones de un cliente
 */
class Direcciones extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            direcciones: {
                data: [],
                limit: 11,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            }
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()
    }

    get = async ({
        page = this.state.direcciones.page,
        limit = this.state.direcciones.limit,
        search = this.props.search,
    } = this.state.direcciones) => {

        this.setState({ loading: true })
        axios.get('/direcciones', {
            params: {
                limit,
                page,
                search,
                cliente_id: this.props.cliente_id
            }
        })
        .then(({ data }) => {
            this.setState({ direcciones: {
                ...data,
                limit,
                data: [ ...data.data, {add: true}]
            } })
        })
        .catch(res => {
            console.log("res", res);
            message.error(res.response?.data?.message ?? "Error" )
        })
        .finally(() => this.setState({ loading: false }))
    }


    delete = (id) => {
        axios.delete('/direcciones', {
            params: {
                id
            }
        })
        .then(({ data }) => {
            message.success("Address deleted!")
            this.get()
        })
        .catch(res => {
            message.error(res.response?.data?.message ?? "Error" )
        })
    }

    renderButton = () => (
        <button className='direcciones-add' disabled={!this.props.canCreate} onClick={() => this.setState({modal_visible: true, direccion_id: undefined})}>
            <PlusOutlined style={{fontSize: 24}}/>
        </button>
    )


    render() {
        return (
            <>
                    
                <List
                    loading={this.state.loading}
                    grid={{
                        gutter: 24,
                        column: 4,
                        xs: 1,
                        md: 2,
                        lg: 4
                    }}
                    className='direcciones-list'
                    locale={{ emptyText: "Direcciones sin registros" }}
                    dataSource={this.state.direcciones.data}
                    pagination={{
                        current: this.state.direcciones.page,
                        pageSize: this.state.direcciones.limit,
                        total: this.state.direcciones.total,
                        hideOnSinglePage: false,
                        position: 'bottom',
                        onChange: (page, limit) => this.get({ page, limit })

                    }}

                    renderItem={item => (
                        <List.Item className='direcciones-list-item'>
                            {
                                item.add ? (
                                    this.renderButton()
                                ) : (
                                    <CardAddress
                                        item={item}
                                        disabledEdit={!this.props.canEdit}
                                        disabledDelete={!this.props.canDelete}
                                        onEdit={() => this.setState({modal_visible: true, direccion_id: item._id})}
                                        onDelete={() => this.delete(item._id)}
                                    />
                                )
                            }
                        </List.Item>
                    )}
                />

                <ModalDirecciones
                	visible={this.state.modal_visible}
                	onClose={()=>{
                		this.setState({modal_visible: false, direccion_id: undefined})
                		this.get()
                	}}
                	cliente_id={this.props.cliente_id}
                	direccion_id={this.state.direccion_id}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "direcciones", "create"],
        canEdit: ["clientes", "direcciones", "edit"],
        canDelete: ["clientes", "direcciones", "delete"]
    })

    const navigate = useNavigate();


    return <Direcciones {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       