import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Modal,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu,
} from 'antd'
import { useNavigate } from "react-router-dom";
import axios, { } from 'axios';
import { WarningFilled } from '@ant-design/icons';

import User from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton'
import { IconEye } from '../../../Widgets/Iconos'
import Sort from "../../../Widgets/Sort"
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';

import '../../../../Styles/Modules/Admin/clientes.scss'
import moment from 'moment';

import { getResponseError } from '../../../Utils';
import ModalClientes from './ModalClientes';

const { Content } = Layout;
const { Text } = Typography
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        //let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,

            clientes: {
                data: [],
                page: 1,
                limit: 10,
                filters: [],
                total: 0,
                sort: {},
            }
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters })

    }



    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({

        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        sort = this.state.clientes.sort,

    } = this.state.clientes, { clientes } = this.state
    ) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search

        this.setState({ clientes: { ...this.state.clientes, page, limit, search, sort }, loading: true })
        axios.get('/clientes', {
            params
        })
            .then(({ data }) => {

                console.log("data clientes", data)
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        data: data.data,
                        total: data.total,
                        search
                    }
                })

            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    renderEstatus = (status) => {

        switch (status) {
            case 0:
                return <Tag color="#FF4D4F">Desistió / Perdido</Tag>
            case 1:
                return <Tag color="#FFE608">Registrado</Tag>
            case 2:
                return <Tag color="#5465FF">Contactado</Tag>
            case 3:
                return <Tag color="#F2802E">Ya compró algo</Tag>
            case 4:
                return <Tag color="#00CD98">Pagado</Tag>
            default:
                return <Tag color="#FFE608">Registrado</Tag>
        }
    }

    updateEstatus = (cliente_id, estatus) => {
        axios.put('/clientes', {
            id: cliente_id,
            estatus
        }).then(success => {
            message.success("Estatus de cliente actualizado")
            this.getClientes()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        })
    }

    /**
     *
     *
     * @param {*} key
     * @memberof ClientesList
    */
    setSort = (key) => {
        let value;
        switch (this.state.clientes.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getClientes({
            sort: {
                ...this.state.clientes.sort,
                [key]: value
            }
        })
    }

    render() {
        const { clientes, loading } = this.state
        return (
            <>
               
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Clientes sin registros" }}
                                dataSource={clientes.data}
                                pagination={{
                                    current: clientes.page,
                                    pageSize: clientes.limit,
                                    total: clientes.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    onChange: (page, limit) => this.getClientes({ page, limit })
                                }}
                                header={
                                    <Row align="middle">
                                       
                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={5} className="center" style={{ color: "gray" }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort['nombre']} onClick={() => this.setSort("nombre")} >
                                                        <Text ellipsis strong>Nombre Completo</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["telefono"]} onClick={() => this.setSort("telefono")} >
                                                        <Text ellipsis strong>Teléfono</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={6} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["email"]} onClick={() => this.setSort("email")} >
                                                        <Text ellipsis strong>Correo</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["estatus"]} onClick={() => this.setSort("estatus")} >
                                                        <Text ellipsis strong>Estatus</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["createdAt"]} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Fecha de Registro</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={5} className="center" >
                                                            <Text ellipsis strong>{item.nombre} {item.apellidos}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item.telefono ? item.telefono : "-"}</Text>
                                                        </Col>
                                                        <Col xs={6} className="center" >
                                                            <Text ellipsis >{item.email}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 0)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-1`} />
                                                                            <Text>Desistió / Cancelado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-2`} />
                                                                            <Text>Registrado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-3`} />
                                                                            <Text>Contactado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-4`} />
                                                                            <Text>Ya compró algo</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 4)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-5`} />
                                                                            <Text>Pagado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="clientes-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.canEdit}
                                                            >
                                                                {this.renderEstatus(item.estatus)}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Space wrap>
                                                                <Button 
                                                                    type='primary' 
                                                                    className='btn-primary' 
                                                                    icon={<IconEye />} 
                                                                    disabled={!this.props.canViewDetails}
                                                                    onClick={()=>this.props.navigate(`/admin/crm/clientes/${item._id}`)}
                                                                />
                                                                <ButtonEdit
                                                                    disabled={!this.props.canEdit}
                                                                    onClick={() => this.setState({ modalCliente: true, cliente_id: item._id })} />
                                                                <ButtonDelete
                                                                    disabled={!this.props.canDelete}
                                                                    onConfirm={() => {
                                                                        confirm({
                                                                            title: "¿Quieres eliminar este cliente?",
                                                                            icon: <WarningFilled />,
                                                                            content: "Todo lo relacionado a él será eliminado",
                                                                            okText: "Eliminar",
                                                                            cancelText: "Cancelar",
                                                                            onOk: () => axios.delete('/clientes', { params: { cliente_id: item._id } })
                                                                                .then((response) => {
                                                                                    message.success("Eliminado correctamente")
                                                                                    this.getClientes()
                                                                                })
                                                                                .catch((error) => {
                                                                                    message.error(getResponseError(error.response, "Error al eliminar"));
                                                                                    this.getClientes()
                                                                                }),
                                                                            onCancel: () => { }
                                                                        })
                                                                    }} />

                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {this.props.canCreate && <FloatingButton title="Nuevo Cliente" disabled={!this.props.canCreate} onClick={() => this.setState({ modalCliente: true, cliente_id: null })} />}
                <ModalClientes
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        this.getClientes()
                    }}

                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "detalles"]
    })

    const navigate = useNavigate();


    return <ClientesList {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}