import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Button, Typography, Switch, InputNumber } from 'antd';
import axios from "axios"

//componentes
import { getResponseError } from "../../Utils";
import SelectCategoria from "../../Widgets/Inputs/SelectCategoria";
import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";
import SelectSize from "../../Widgets/Inputs/SelectSizes";
import SelectCatalogos from "../../Widgets/Inputs/SelectCatalogos";

const { Text } = Typography


/**
 * @class ModalProductos
 * @description Modal para el CRUD de Productos
 */
class ModalProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.producto_id)
            this.getProducto()

    }

    formRef = React.createRef();

    /**
    * @method getProducto
    * @description Obtener al producto
    */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get('/productos/' + this.props.producto_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                    imagenes: data.imagenes ? data.imagenes.map(f => ({
                        uid: f.file,
                        name: f.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/productos/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    categoria_id: data.categoria_id ? {
                        label: data.categoria_id?.nombre,
                        value: data.categoria_id?._id,
                    } : null,
                    size_id: data.size_id ? {
                        label: data.size_id?.nombre,
                        value: data.size_id?._id,
                    } : null,
                    catalogo_id: data.catalogo_id ? {
                        label: data.catalogo_id?.nombre,
                        value: data.catalogo_id?._id,
                    } : null
                })
                this.setState({ producto: data.producto_id })
            }).catch(error => {
                message.error('Error al obtener el producto')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method add
    * @description Añade un nuevo registro de producto
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/productos', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Producto Creado')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al crear producto'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de producto
    */
    update = (values) => {

        this.setState({ loading: true })
        axios.put('/productos', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Producto actualizado')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el producto'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.producto_id,
            imagenes: undefined,
            uris: undefined
        })

        if (values.imagenes) {
            values.imagenes.forEach(img => {
                if (!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if (values.size_id?.value) formData.set('size_id', values.size_id?.value)
        if (values.categoria_id?.value) formData.set('categoria_id', values.categoria_id?.value)
        if (values.catalogo_id?.value) formData.set('catalogo_id', values.catalogo_id?.value)


        if (this.props.producto_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-3"
                    initialValues={{
                        activo: true
                    }}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="descripcion"
                                label="Descripción"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la descripción',
                                    }
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>

                            <Form.Item
                                name="marca"
                                label="Marca"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la marca',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="modelo"
                                label="Modelo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el modelo',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="precio_venta"
                                label="Precio de Venta"
                            >
                                <InputNumber
                                    min={0.01}
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>

                            <Form.Item
                                name="sku"
                                label="SKU"
                                rules={[
                                    {
                                        required: true,
                                        message: 'SKU',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="sku_variante"
                                label="SKU VARIANTE"
                                rules={[
                                    {
                                        required: true,
                                        message: 'SKU',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="activo"
                                        label="Activo"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="hot"
                                        label="Hot Sale"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="inventariar"
                                        label="Invertariar"
                                        valuePropName="checked"
                                    >
                                        <Switch onChange={e => this.setState({ inventariar: e })}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                this.state.inventariar ? <Form.Item
                                    name="stock"
                                    label="Inventario"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Se requiere el inventario',
                                        }
                                    ]}
                                >
                                    <InputNumber controls={false} className="width-100"/>
                                </Form.Item> : null
                            }


                            <Form.Item
                                name="size_id"
                                label="Tamaño"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona el tamaño',
                                    }
                                ]}
                            >
                                <SelectSize />
                            </Form.Item>

                            <Form.Item
                                name="categoria_id"
                                label="Categoria"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona la categoria',
                                    }
                                ]}
                            >
                                <SelectCategoria />
                            </Form.Item>

                            <Form.Item
                                name="catalogo_id"
                                label="Catálogo"
                            >
                                <SelectCatalogos />
                            </Form.Item>

                            <Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader
                                    listType="picture-card"
                                    showUploadList={true}
                                    imageCrop={true}
                                    onRemove={({ name, fromDB }) => {
                                        if (fromDB) axios.put(`/productos`, {
                                            id: this.props.producto_id,
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, producto_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={producto_id ? "Editar Producto" : "Crear Producto"}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        zIndex={1000}
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductos  {...props} />
    </Modal>

}