import React, { Component } from 'react'
import {Row, Col, Form, Input, Button, Modal, Spin, Card, Typography, message} from 'antd';
import { Navigate, useParams, useNavigate } from "react-router-dom";


import axios from 'axios';
const { Title } = Typography

/**
 *
 *
 * @class UpdatePassword
 * @extends {Component}
 */
class UpdatePassword extends Component {



    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {},
            loading: false
        }
    }

    recoveryPassword = React.createRef();

    redirectTo = "/"


    /**
     *
     *
     * @memberof UpdatePassword
     */
    async componentDidMount(){
        window.a = this
        const { token } = this.props.params;
        if (token === undefined) {
            this.redirectTo = "/";
            this.setState({ redirect: true })
        } else {
            this.recoveryPassword.current.setFieldsValue({ token });
        }
    }


    /**
     *
     *
     * @memberof UpdatePassword
     */
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to={this.redirectTo} replace />;
        }
    };


    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {

        console.log("values", values)
        this.setState({ loading: true })
        axios.put('/password/recovery/update', values)
            .then(() => {
               message.success("Contraseña Actualizada")
               this.props.navigate('/login')

            })
            .catch((error) => {
                console.log(error.response)
                Modal.error({
                    title: "No se puede actualizar",
                    content: "El token no es valido o el email no existe."
                })
            })
            .finally(() => this.setState({
                loading: false
            }))
    };


    render() {
        return (
            <Row className='layout-login'>
                <Col span={10} className="col-welcome">
                    <Row>
                        <div>
                            <img width="400" src="/img/logo.svg" alt="Sneacare" />
                            
                        </div>
                    </Row>
                </Col>
                <Col span={14}>
                    <Spin spinning={this.state.loading}>
                        <div className='login-form-container'>
                            <Form
                                layout={"vertical"} requiredMark={false} className='login-form'
                                name="basic" 
                                onFinish={this.onFinish} 
                                initialValues={this.state.form} 
                                ref={this.recoveryPassword}
                            >
                                <Title level={3} className="h3-title mb-3">Actualizar Contraseña</Title>
                                <Form.Item name="token" noStyle >
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: 'Para iniciar sesión, ingresa tu email.'
                                    }]}
                                >
                                    <Input className="input-login" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Contraseña"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa tu contraseña',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password className="input-login" />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Confirma tu contraseña',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Las contraseñas no coinciden');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password className="input-login" />
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" htmlType="submit" block size="large" >
                                        Actualizar Contraseña
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Spin>
                </Col>
            </Row>
        )
    }
}



export default function Vista(props) {
    console.log("UdatePassword", props)
    return <UpdatePassword {...props} params={useParams()} navigate={useNavigate()} />

}