import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Col, Layout, Row, Space, Tabs, Typography, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//componentes
import usePermissions from "../../../../Hooks/usePermissions";
import { CardSimpleInfo } from '../../../Widgets/Cards';
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import Direcciones from "./Tabs/Direcciones";

//css
import '../../../../Styles/Modules/Admin/CRM.scss';
import { moneyFormat } from '../../../Utils';
import ModalClientes from './ModalClientes';
import Compras from './Tabs/Compras';
import User from '../../../../Hooks/Logged';


const { Content } = Layout;
const { Title, Text } = Typography
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class ClientesDetalles
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un cliente
 */
class ClientesDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente_id: this.props.params.cliente_id,
            cliente: {

            },
            ventas: 0,
            deudas: 0,
            status: []
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.cliente_id) {
            this.getCliente()
            this.getVentas()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.state.cliente_id, )
            .then(response => {
                this.setState({
                    cliente: response.data
                })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener la informacion del cliente ")
            }).finally(() => this.setState({ loading: false }))
    }

    getVentas = () => {
        axios.get('/clientes/ventas/' + this.state.cliente_id)
            .then(({data}) => {
                this.setState({
                    ...data
                })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener la informacion del cliente ")
            })
    }


    render() {

        const { cliente, ventas, status, deudas } = this.state

        const items  = []
    
        if(this.props.verClienteCompras) items.push({
            key: 'compras',
            label: 'Compras',
            children: <Compras getData={() => this.getVentas()} cliente_id={this.props.params.cliente_id} />,
        })
        if(this.props.verClienteDirecciones) items.push({
            key: 'direcciones',
            label: 'Direcciones',
            children: <Direcciones cliente_id={this.props.params.cliente_id} />,
        })

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Clientes",
                                onClick: () => this.props.navigate('/admin/crm/clientes'),
                                className: "link"
                            },
                            {
                                title: ` ${cliente?.nombre} ${cliente?.apellidos}`,
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <CardSimpleInfo
                                title="Cliente"
                                content={
                                    <Row className="mt-1">
                                        <Col span={24}>
                                            <Text strong>Cliente desde {moment(cliente.createdAt).format("MM/DD/YYYY")}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{cliente.nombre} {cliente.apellidos}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{cliente.telefono}</Text>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <CardSimpleInfo
                                title="Estatus"
                                content={
                                    <Row gutter={[8, 8]} className="mt-1" align={"middle"}>
                                        <Col span={4} className="center">
                                            <ClockCircleFilled style={{ fontSize: "24px", color: "#faad14" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>En Progreso</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{status[1] ?? 0}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <CheckCircleFilled style={{ fontSize: "24px", color: "#52c41a" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>Completado</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{status[2] ?? 0}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <CloseCircleFilled style={{ fontSize: "24px", color: "#ff4d4f" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>Cancelado</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{status[3] ?? 0}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <MinusCircleFilled style={{ fontSize: "24px", color: "#ff4d4f" }} />
                                        </Col>
                                        <Col span={10}>
                                            <Text>Rembolsado</Text>
                                        </Col>
                                        <Col span={10} className="center">
                                            <Text>{status[4] ?? 0}</Text>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} xl={8} className="grid">
                            <Space size={16} direction='vertical'>
                                <CardSimpleInfo
                                    title="Ventas Totales"
                                    info={`${moneyFormat(ventas)}`}
                                />
                                <CardSimpleInfo
                                    title="Créditos Totales"
                                    info={`${moneyFormat(deudas)}`}
                                />
                            </Space>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="compras"
                        destroyInactiveTabPane={true}
                        items={items}
                    />

                </Content>
                <ModalClientes
                    visible={this.state.modal_visible}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getCliente()
                    }}

                />

            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()
    const user = React.useContext(User)

    const permisos = usePermissions(user?.rol_id?.permisos, {
        verClienteCompras: ["clientes", "compras", "access"],
        verClienteDirecciones: ["clientes", "direcciones", "access"],
    })



    return <ClientesDetalles  {...props} params={params} navigate={navigate}
        {...permisos}
    />
}