import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import axios from 'axios';
import Dashboard from '../Components/Admin/Dashboard/Dashboard';
import Navbar from '../Components/Admin/Navigation/Navbar';
import Sidebar from '../Components/Admin/Navigation/Sidebar';

import User from '../Hooks/Logged';
import '../Styles/Global/admin.scss';
import '../Styles/Global/global.scss';
import RouterRoles from './Admin/RouterRoles';
import RouterUsuarios from './Admin/RouterUsuarios';

import { Search } from '../Hooks/useSearch';
import RouterVentas from './Admin/RotuerVentas';
import RouterCRM from './Admin/RouterCRM';
import RouterCategorias from './Admin/RouterCategorias';
import RouterInventarios from './Admin/RouterInventarios';
import RouterProductos from './Admin/RouterProductos';
import RouterSizes from './Admin/RouterSizes';
import RouterCatalogos from './Admin/RouterCatalogos';

const { Content } = Layout

/**
 * 
 * @export
 * @function AdminRoutes
 * @description Router for handling all admin routes
 */
class AdminRoutes extends React.Component {

  static contextType = User


  constructor(props) {
    super(props)
    this.state = {
      search: null,
      showSearch: true,
      clear: true
    }
  }

  componentDidMount() {
    this.axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        //console.log('response interceptor', response)
        return response;
      },
      (error) => {
        console.log('error interceptor', error?.response?.status)
        if (error.response?.status === 401)
          this.props.navigate('/login')
        return Promise.reject(error);
      }
    );
  }
  componentWillUnmount() { axios.interceptors.request.eject(this.axiosInterceptor); }

  setSearch = search => this.setState({ search })


  setShowSearch = showSearch => this.setState({ showSearch })

  componentWillUnmount() {
    axios.interceptors.request.eject(this.updateToken)
  }
setFilterSearch = (search) => {
		this.setState({search})
	}
  render() {
    return (
        <Search.Provider value={{
          search: this.state.search,
          setSearch: this.setSearch,
          show: this.state.showSearch,
          setShow: this.setShowSearch
        }}>
          <Layout className='layout-main'>
            <Navbar showSearch={this.state.showSearch} onSearch={this.setSearch} />
            <Layout>
              <Sidebar />
              <Content style={{ minHeight: 'calc(100vh - 100px)' }}>
                <Routes>
                  <Route path='/dashboard'      element={<Dashboard search={this.state.search}/>} />
                  <Route path='/usuarios/*'     element={<RouterUsuarios search={this.state.search}/>} />
                  <Route path='/productos/*'    element={<RouterProductos search={this.state.search}/>} />
                  <Route path='/catalogos/*'    element={<RouterCatalogos search={this.state.search}/>} />
                  <Route path='/categorias/*'   element={<RouterCategorias search={this.state.search}/>} />
                  <Route path='/sizes/*'        element={<RouterSizes search={this.state.search}/>} />
                  <Route path='/roles/*'        element={<RouterRoles search={this.state.search}/>} />
                  <Route path='/crm/*'          element={<RouterCRM search={this.state.search}/>} />
                  <Route path='/inventario/*'    element={<RouterInventarios search={this.state.search}/>} />
                  <Route path='/ventas/*'        element={<RouterVentas search={this.state.search}/>} />
                </Routes>
              </Content>
            </Layout>

          </Layout>
        </Search.Provider>
    )
  }


}
export default function (props) {

  const user = useContext(User)
  const navigate = useNavigate()
  return <AdminRoutes {...props} user={user} navigate={navigate} />
}