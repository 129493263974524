import React from 'react'
import { Route, Routes } from "react-router-dom";

import Productos from '../../Components/Admin/Productos/Productos';

/**
 * 
 * @export
 * @function RouterProductos
 * @description Router for Productos routes 
 */
function RouterProductos(props) {
  return (
    <Routes>
      <Route path="" element={<Productos {...props} />} />
    </Routes>
  )
}

export default RouterProductos