import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, Layout, Space, message, Card, List, Tag } from 'antd'
import { CopyOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import useSearch from '../../../Hooks/useSearch';

import ModalInventarios from "./ModalInventarios";
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import { IconMovimientos } from '../../Widgets/Iconos'


const { Content } = Layout;
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class Inventario
 * @extends {Component}
 * @description Vista donde se listan todos los inventarios
 */
class Inventario extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Inventario.
     * @param {*} props
     * @memberof Inventario
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            inventarios: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getInventarios()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getInventarios()
        }
    }

    /**
    *
    * @memberof Inventario
    * @method getInventarios
    * @description Obtiene la lista de inventarios
    */
    getInventarios = async ({
        page = this.state.inventarios.page,
        limit = this.state.inventarios.limit,
        search = this.props.search,
    } = this.state.inventarios) => {

        this.setState({ loading: true })
        axios.get('/inventarios', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ inventarios: data })
            })
            .catch(res => {
                console.log("res", res);
                message.error(res.response?.data?.message ?? "Error")
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Inventario"
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Productos sin registros" }}
                            dataSource={this.state.inventarios.data}
                            pagination={{
                                current: this.state.inventarios.page,
                                pageSize: this.state.inventarios.limit,
                                total: this.state.inventarios.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getInventarios({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col span={12} className="center">
                                    <Text strong>Producto</Text>
                                </Col>
                                {/* <Col span={6} className="center">
                                    <Text strong>Categoría</Text>
                                </Col> */}
                                <Col span={6} className="center">
                                    <Text strong>Existencias</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} className="center">
                                                <Text ellipsis>{item.producto_id?.nombre}</Text>
                                            </Col>
                                            {/* <Col xs={6} className="center">
                                                <Text ellipsis>{item.categoria_id?.nombre}</Text>
                                            </Col> */}
                                            <Col xs={6} className="center">
                                                <Text ellipsis>{item.existencias.toMoney(true)}</Text>
                                            </Col>
                                            <Col xs={6} className="flex-right">
                                                <Space>
                                                    <ButtonView
                                                        disabled={!this.props.canViewMovimientos}
                                                        title="movimientos"
                                                        onClick={() => this.props.navigate('/admin/inventario/movimientos/' + item._id)}
                                                        icon={<IconMovimientos />}
                                                    />
                                                    <ButtonEdit
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({ modal_visible: true, inventario_id: item._id })}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                    </>}

                    <ModalInventarios
                        open={this.state.modal_visible}
                        onClose={(flag) => {
                            this.setState({ modal_visible: false, inventario_id: undefined })
                            if (flag === true)
                                this.getInventarios()
                        }}
                        inventario_id={this.state.inventario_id}
                    />

                </Spin>
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['inventario', 'edit'],
        canCreate: ['inventario', 'create'],
        canViewMovimientos: ['inventario', 'movimientos', 'access']
    })

    return <Inventario {...props} setShowSearch={setShow} {...permisos} navigate={useNavigate()} />
}