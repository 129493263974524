import React, { Component, } from "react";
import { Modal, Form, Input, message, Spin, Tree, Tooltip, Radio } from 'antd';
import axios from "axios";


/*
    Los permisos están definidos bajo la siguiente estructura:
      * permiso padre | permiso padre | permiso hijo
    Cuando existe un padre, pero no existe ningun hijo, se da por hecho que están todos los hijos. 
    Cuando existe un hijo, se tiene el permiso parcial del padre. 
    Debemos iterar los permisos, si está un padre solo, es necesario 
*/


/**
 *
 *
 * @class ModalRoles
 * @extends {Component}
 */
class ModalRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            permisos: [],

            //Arreglo de Permisos Completo
            permissionsDictionary: {},


            //Arreglo de Permisos para el Tree Sleect
            selectedPermissions: [],

            //Donde se encuentra localizado el permiso
            selectedPermissionsDictionary: {},

            //Aquellos requeridos
            selectedPermissionsRequired: {},

            //ARBOL DE PERMISOS por defecto, todos son falsos. Al Editar / Gaurdar se cambian los valores con base el arreglo de ANTD
            permissionsTree: {},

            editable: true
        }
    }

    ModalRoles = React.createRef();

    componentDidMount() {
        this.getPermisos()
        if (this.props.rol_id !== undefined) {
            this.get()
        }
    }




    /**
     * @memberof ModalRoles
     * @method get
     * @description Obtiene una rol de la DB
     */
    get = () => {
        this.setState({ loading: true })
        axios.get(`/roles/${this.props.rol_id}`, {
            params: {
                id: this.props.rubro
            }
        }).then(({ data }) => {
            let selectedPermissions = []
            //Metodo recurisvo para formatear el objeto de permisos a un arreglo 
            //interpretable por el TREE ANTD
            let updateTreePermissions = (item) => {
                //R es el elemento a revisar, y P es el key
                //Metodo iterativo
                let iterateTree = (tree, key, path) => {
                    //Si no hay un key, simplemente iteramos el TREE y lo llamamos recursivamente
                    if (!key) {
                        for (const keyChild in tree)
                            iterateTree(tree, keyChild, keyChild);
                        return 
                    }
    
                    //Si es un objeto, iterammos el objeto y vamos llenando el PATH hasta recorrer todo
                    //el objeti
                    if (typeof tree[key] === "object") {
                        path = (path ?? "");
                        //Iteramos los objetos y vamos recursivamente hasta obtener todo el arreglo.
                        for (const keyChild in tree[key])
                            //Ocupo evaluar al padre y al hijo
                            iterateTree(tree[key], keyChild, (path ? (path + "|") : "") + keyChild);
    
                    } else {

                        //Si el permiso está, se agrega al arreglo de permisos
                        if (tree[key])
                            selectedPermissions.push(path)
                    }
                    return tree;
                }
                return iterateTree(item);
            }
            updateTreePermissions(data.permisos)

            this.setState({
                editable: data.editable,
                selectedPermissions
            })
            this.ModalRoles.current.setFieldsValue({

                ...data
            })
        }).catch(error => {
            message.error('Error al cargar los permisos')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalRoles
     * @method getPermisos
     * @description Obtenemos los permisos actuales en el sistema
     */
    getPermisos = () => {
        this.setState({ loading: true })
        axios.get(`/roles/permisos`).then(({ data }) => {

            let permissionsDictionary = {}
            let permissionsTree = {}

            //Metodo recursivo para formatear el arreglo de permisos 
            //a algo que pueda interpretar el ANTD
            let formatPermisos = (permission, name, permissionsTree) => {
                let cPermission = {
                    title: permission.title
                }
                cPermission['name'] = (name ? (name + "|") : "") + permission.name
                cPermission['key'] = cPermission['name']
                if (permission?.description)
                    cPermission["description"] = permission?.description

                if (permission.required)
                    cPermission['required'] = permission.required

                permissionsDictionary[cPermission['name']] = { ...cPermission }

                if (Array.isArray(permission.permissions)) {
                    if (!permissionsTree[permission.name])
                        permissionsTree[permission.name] = {}
                    cPermission['children'] = permission.permissions.map(item => formatPermisos(item, cPermission['name'], permissionsTree[permission.name]))
                }
                else
                    permissionsTree[permission.name] = false

                return cPermission
            }

            let permisos = data.map(permission => formatPermisos(permission, undefined, permissionsTree))
            this.setState({
                permisos,

                permissionsTree,
                permissionsDictionary,
                permissionsArray: Object.keys(permissionsDictionary)
            })
        })
            .catch(error => {
                console.log("error", error)
                message.error('Error a cargar los permisos')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof ModalRoles
    * @method addRol
    * @description Añade una rol a la BD
    */
    addRol = (values) => {
        this.setState({ loading: true })
        axios.post('/roles', {
            ...values
        }).then(response => {
            message.success('Rol creado existosamente')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear el rol.')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalRoles
     * @method updateRol
     * @description Actualiza la información de un rol
     */
    updateRol = (values) => {
        this.setState({ loading: true })

        axios.put('/roles', {
            ...values,
            rol_id: this.props.rol_id,
        }).then(response => {
            message.success('Rol actualizado exitosamente')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar el rol')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalRoles
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        let { selectedPermissions, permissionsTree } = this.state
        this.props.onClose()
        permissionsTree = { ...this.updateTreePermissions(permissionsTree) }
        values.permisos = { ...permissionsTree }

        if (this.props.rol_id)
            this.updateRol(values)
        else
            this.addRol(values)
    }

    /**
     * @memberof ModalRoles
     * @method updateTreePermissions
     * @description Actualizamos el ARBOL de permisos según el arreglo de elementos selecionados en el TREE ANTD
     */
    updateTreePermissions = (item, selectedPermissionsDictionary = this.state.selectedPermissions.reduce((acc, curr) => (acc[curr] = true, acc), {})) => {
        //R es el elemento a revisar, y P es el key
        let iterateTree = (tree, key, path) => {
            if (!key)
                for (const keyChild in tree)
                    iterateTree(tree, keyChild, keyChild);

            if (typeof tree[key] === "object") {
                path = (path ?? "");
                for (const keyChild in tree[key])
                    //Ocupo evaluar al padre y al hijo
                    iterateTree(tree[key], keyChild, (path ? (path + "|") : "") + keyChild);

            } else
                tree[key] = Boolean(selectedPermissionsDictionary[path]);

            return tree;
        }
        return iterateTree(item);
    }


    /**
     * @memberof ModalRoles
     * @method updateTreePermissions
     * @description Se encarga de evaluar si alguno de los elementos dependen de otros elementos, esto para todos los permisos dependiendtes de otros permisos coincida. 
     */
    actualizarArbolDePermisos = (selectedPermissions, allConfig) => {


        const {
            permissionsArray,
            selectedPermissionsRequired,
            permissionsDictionary
        } = this.state

        const { checked, node } = allConfig
        const { name } = node

        //Transformo el arreglo de permisos a un objeto
        let selectedPermissionsDictionary = selectedPermissions.reduce((acc, curr) => (acc[curr] = '', acc), {});

        /*
        Cuando se seleccciona un permiso, se debe de revisar todos aquellso permisos que 
        dependen de ese permiso o bien, los hijos de ese permiso
        */
        if (checked) {

            let getAllPermissionsRequired = (permission) => {

                //Obtengo todos los permisos HIJOS de este permiso
                let permisosHijos = permissionsArray.filter(permissionArray => permissionArray.startsWith(permission))
                let permisosRequired = {}
                // let permisosRequired = {}
                //Iteramos cada hijo, obtenemos lo que require cada uno
                for (const permisoHijo of permisosHijos) {

                    //Obtenemos los permisos que require cada hijo
                    if (permissionsDictionary[permisoHijo].required) for (const permisoHijoRequired of permissionsDictionary[permisoHijo].required) {

                        let permisosParaAgregar = permissionsArray.filter(permissionArray => (permissionArray.startsWith(permisoHijoRequired)))

                        permisosParaAgregar.map(permisoHijoRequired => {

                            permisosRequired[permisoHijoRequired] = true
                            if (!selectedPermissionsRequired[permisoHijoRequired])
                                selectedPermissionsRequired[permisoHijoRequired] = {}

                            selectedPermissionsRequired[permisoHijoRequired][name] = true
                            selectedPermissionsRequired[permisoHijoRequired][permisoHijo] = true
                            //Obtenemos los permisos que requieren los permisos requeridos  
                            permisosRequired = { ...permisosRequired, ...getAllPermissionsRequired(permisoHijoRequired) }

                        })


                    }
                }
                return permisosRequired;
            }

            //Itero todos los permisos requeridos, si no existen se agregan al arbol de permisos
            for (const permisoRequired in getAllPermissionsRequired(name)) {
                let permisosParaAgregar = permissionsArray.filter(permissionArray => (permissionArray.startsWith(permisoRequired)))
                for (const permisoParaAgregar of permisosParaAgregar) {
                    selectedPermissionsDictionary[permisoParaAgregar] = true
                }
            }
            //Transformo a un arreglo para que el TREE lo pueda interpretar.
        }
        /*
        Cuando se deselecciona, se deben deseleccionar todos aquellos permisos que dependan de ese permiso. 
        */
        else {
            let removePermissionsRequired = selectedPermissionsRequired[name];
            for (const removePermission in removePermissionsRequired)
                for (const selectedPermissionDictionary in selectedPermissionsDictionary)
                    if (selectedPermissionDictionary.startsWith(removePermission) || !selectedPermissionDictionary.includes("|"))
                        delete selectedPermissionsDictionary[selectedPermissionDictionary]
        }
        this.setState({ selectedPermissions: Object.keys(selectedPermissionsDictionary), selectedPermissionsRequired })
    }


    render() {
        return (
            <Form
                layout="vertical"
                name="form-roles"
                id="form-roles"
                ref={this.ModalRoles}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Ingresa el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                    >
                        <Input placeholder="Descripción" ></Input>
                    </Form.Item>
                    <Form.Item
                        label={<>Los usuarios que tienen este rol: </>}
                        name="tipo"
                        rules={[{
                            required: true,
                            message: "Ingresa el tipo"
                        }]}
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            disabled={this.state.editable === false}
                        >
                            <Radio.Button value={1} style={{width: "50%", height: 65, textAlign: "center"}}>Puedes ver todo lo que hay en el sistema</Radio.Button>
                            <Radio.Button value={2} style={{width: "50%", height: 65, textAlign: "center"}}>Solo puedes ver lo asignado por el rol</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Permisos"
                        name="permisos"
                    >
                        <Tree
                            onCheck={this.actualizarArbolDePermisos}
                            checkedKeys={this.state.selectedPermissions}
                            disabled={this.state.editable === false}
                            checkable
                            treeData={this.state.permisos}
                            defaultExpandAll={true}

                            titleRender={(node ) => {
                                console.log("node", node)

                                if (node.description)
                                    return <Tooltip title={node.description} placement="right">{node.title}</Tooltip>

                                return node.title
                            }}
                        />
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}

export default function (props) {
    const { visible = false, onClose = () => { }, rol_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={rol_id ? "Crear Rol" : "Editar Rol"}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        okButtonProps={{ form: 'form-roles', key: 'form-roles', htmlType: 'submit' }}
    >
        <ModalRoles {...props} />
    </Modal>


}