import React from 'react';
import { Modal, Button, Drawer } from 'antd';
import PreviewWrapper from '../PreviewWrapper';
// import './style.less';

const { confirm } = Modal;

class ModalPreview extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  showConfirm = () => {
    confirm({
      title: 'Do you Want to delete these items?',
      content: 'Some descriptions',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this task?',
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  render() {
    const { size, disabled } = this.props;

    return (
      <PreviewWrapper id="Drawer" title="Drawer">
        <div className="components">
          <div className="component-row">
            <div className="component-col">
              <Button type="primary" onClick={this.showModal} size={size} disabled={disabled}>
                Open Drawer
              </Button>
            </div>
          </div>
          <Drawer
            title="Basic Drawer"
            visible={this.state.visible}
            onOk={this.handleOk}
            // ={}
            onClose={this.handleCancel}
            placement={this.state.placement || 'right'}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Drawer>
        </div>
      </PreviewWrapper>
    );
  }
}

export default ModalPreview;
