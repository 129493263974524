import React, { Component } from 'react';
import {
    Row, Col, Typography,
    Layout, Space, message, List, Card, Collapse
} from 'antd'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import useSearch from '../../../Hooks/useSearch';



import FloatingButton from '../../Widgets/Floating Button/FloatingButton'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import ModalVentas from './ModalVentas';

import '../../../Styles/Modules/Admin/ventas.scss'

import dayjs from 'dayjs';
import { moneyFormat } from '../../Utils';


const { Content } = Layout;
const { Text } = Typography
/**
 *
 *
 * @export
 * @class VentasTab
 * @extends {Component}
 * @description Vista donde se listan todos los VentasTab
 */
class VentasTab extends Component {



    /**
     *Creates an instance of VentasTab.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            ventas: {
                data: {
                    1: [],
                    2: [],
                    3: [],
                    4: [],
                },
                skip: 0,
                limit: 20
            },
            panels: [1, 2, 3, 4],
            search: this.props.search,
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {

        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas({ filters: this.state.filters });
        const self = this
        window.onscroll = function () {
            if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.ventas.skip < self.state.ventas.total) {
                self.getVentas({ skip: (parseInt(self.state.ventas.skip) + parseInt(self.state.ventas.limit)) })
            }
        }
    }

    componentWillUnmount() {
        window.onscroll = null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getVentas({ search: this.props.search, skip: 0 })
        }
    }

    /**
    * @memberOf VentasTab
    * @method getVentas
    * @description Obtiene ls lista de Ventas
    */
    getVentas = ({
        skip = this.state.ventas.skip,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search,
        filters = this.state.ventas.filters,
        panels = this.state.panels,
        sort = this.state.sort
    } = this.state.ventas, { } = this.state
    ) => {
        const params = { limit, skip }
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        let clearCheckbox = (skip === 0)

        this.setState({ loading: true, panels, clearCheckbox, sort })
        axios.get('/ventas', {
            params: {
                skip: this.state.first ? 0 : skip,
                limit: (this.state.first && skip > 0) ? skip : limit,
                panels,
                search: this.props.search,
                filters,
                group: "estatus",
                sort
            }
        })
            .then(({ data }) => {
                console.log('ventas=>', data)
                this.setState({
                    ventas: {
                        ...data,
                        data: {
                            1: (skip == 0) ? (data.data[1] ?? []) : this.state.ventas.data[1].concat(data.data[1] ?? []),
                            2: (skip == 0) ? (data.data[2] ?? []) : this.state.ventas.data[2].concat(data.data[2] ?? []),
                            3: (skip == 0) ? (data.data[3] ?? []) : this.state.ventas.data[3].concat(data.data[3] ?? []),
                            4: (skip == 0) ? (data.data[4] ?? []) : this.state.ventas.data[4].concat(data.data[4] ?? []),
                        },
                        skip,
                        limit,
                        //filters: filters
                    },
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }

    render() {

        const { ventas, loading } = this.state

        let estatus = [
            {
                _id: 1,
                name: 'pendientes',
                label: "Pendientes"
            },
            {
                _id: 2,
                name: 'completados',
                label: "Completados"
            },
            {
                _id: 3,
                name: 'cancelados',
                label: "Cancelados"
            },
            {
                _id: 4,
                name: 'rembolsados',
                label: "Rembolsados"
            }
        ]


        return (
            <>
                <Content
                    className="admin-content content-bg ventas pd-1"
                    id="ventas-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="ventas-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={e => this.getVentas({ skip: 0, panels: e })}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={label}
                                        className={`ventas-tab tab-${name}`}
                                    >
                                        <List
                                            loading={ventas.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Cargando" : "Sin registros " }}
                                            dataSource={this.state.ventas.data[_id]}
                                            renderItem={(item, index) => (
                                                <List.Item className="component-list-item" key={item._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">

                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 12]}>
                                                                    <Col xs={4} className="center" >
                                                                        <Text ellipsis strong>{dayjs(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                                    </Col>
                                                                    <Col xs={6} className="center" >
                                                                        <Text ellipsis >{item?.cliente_id?.nombre} {item?.cliente_id?.apellidos}</Text>
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Text ellipsis >{item.order_id}</Text>
                                                                    </Col>
                                                                    <Col xs={6} className="center" >
                                                                        <Text ellipsis >{moneyFormat(item.subtotal)}</Text>
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
                                                                            <ButtonView
                                                                                onClick={()=>this.props.navigate(`/admin/ventas/${item._id}`)}
                                                                            />
                                                                            <ButtonEdit onClick={() => this.setState({
                                                                                modalVentas: true,
                                                                                venta_id: item._id
                                                                            })}
                                                                                disabled={!this.props.canEdit}
                                                                            />
                                                                            <ButtonDelete disabled={!this.props.canDelete} onConfirm={() => axios.delete('/ventas', { params: { id: item._id } })
                                                                                .then((response) => {
                                                                                    message.success(response?.data?.message)
                                                                                    this.getVentas()
                                                                                })
                                                                                .catch((error) => {
                                                                                    message.error(error?.response?.data?.message);
                                                                                })} />
                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>

                {this.props.canCreate && <FloatingButton title="New Sale" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVentas: true, venta_id: undefined })} />}

                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />


            </>
        )
    }
}

export default (props) => {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"]
    })

    console.log("permisos", permisos)

    const navigate = useNavigate();
    const location = useLocation();


    return <VentasTab {...props}
        user={user}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}