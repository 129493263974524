import React from 'react'
import { Route, Routes } from "react-router-dom";

import Usuarios from '../../Components/Admin/Usuarios/Usuarios';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router for Usuarios routes 
 */
function RouterUsuarios(props) {
  return (
    <Routes>
      <Route path="" element={<Usuarios {...props} />} />
    </Routes>
  )
}

export default RouterUsuarios