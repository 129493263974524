import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import {
    MenuUnfoldOutlined, MenuFoldOutlined, AppstoreFilled, DollarOutlined,
    TagsOutlined, DashboardOutlined, UserOutlined, LineChartOutlined, SettingOutlined
} from "@ant-design/icons";
import { BsBoxSeam, BsShieldCheck,BsBoxes ,BsTools, BsBook} from "react-icons/bs"
import SubMenu from "antd/lib/menu/SubMenu";
import usePermissions from "../../../Hooks/usePermissions";
import User from "../../../Hooks/Logged";

const { Sider } = Layout;

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {

        const { props } = this

        return (
            <Sider
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"
                className="custom-sider sider-width"
            >

                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>


                    <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">

                        {props.accessDashboard && <Menu.Item key="dashboard" icon={<DashboardOutlined />} onClick={() => this.props.navigate("/admin/dashboard", { replace: true })}>
                            Dashboard
                        </Menu.Item>}

                        {(props.accessCRM || props.accessClientes) && <SubMenu onTitleClick={() => props.navigate("/admin/crm", { replace: true })} key="crm" title={"CRM"} icon={<SettingOutlined />}>
                            <Menu.Item key="crm-12clientes" onClick={() => this.props.navigate("/admin/crm/clientes", { replace: true })} icon={<AppstoreFilled />} >
                                Clientes
                            </Menu.Item>
                        </SubMenu>}
                        {props.accessVentas && <Menu.Item key="ventas" icon={<BsTools />} onClick={() => this.props.navigate("/admin/ventas", { replace: true })}>
                            Ventas
                        </Menu.Item>}
                        {props.accessProductos && <Menu.Item key="productos" icon={<BsBoxSeam />} onClick={() => this.props.navigate("/admin/productos", { replace: true })}>
                            Productos
                        </Menu.Item>}
                        {props.accessCatalogos && <Menu.Item key="catalogos" icon={<BsBook />} onClick={() => this.props.navigate("/admin/catalogos", { replace: true })}>
                            Catalogos
                        </Menu.Item>}
                        {props.accessInventario && <Menu.Item key="inventory" icon={<BsBoxes />} onClick={() => this.props.navigate("/admin/inventario", { replace: true })}>
                            Inventario
                        </Menu.Item>}
                        {props.accessCategorias && <Menu.Item key="categories" icon={<TagsOutlined />} onClick={() => this.props.navigate("/admin/categorias", { replace: true })}>
                            Categorias
                        </Menu.Item>}
                        {props.accessSizes && <Menu.Item key="sizes" icon={<TagsOutlined />} onClick={() => this.props.navigate("/admin/sizes", { replace: true })}>
                            Tamaños
                        </Menu.Item>}
                        {props.accessUsuarios && <Menu.Item key="usuarios" icon={<UserOutlined />} onClick={() => this.props.navigate("/admin/usuarios", { replace: true })}>
                            Usuarios
                        </Menu.Item>}
                        {props.accessRoles && <Menu.Item key="roles" icon={<BsShieldCheck />} onClick={() => this.props.navigate("/admin/roles", { replace: true })}>
                            Roles
                        </Menu.Item>}



                    </Menu.ItemGroup>

                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {

    const user = React.useContext(User)

    console.log('user', user)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        accessDashboard: ['dashboard'],
        accessUsuarios: ['usuarios', 'access'],
        accessClientes: ['clientes', 'access'],
        accessProductos: ['productos', 'access'],
        accessCategorias: ['categorias', 'access'],
        accessCatalogos: ['catalogos', 'access'],
        accessSizes: ['sizes', 'access'],
        accessRoles: ['roles', 'access'],
        accessCRM: ['crm'],
        accessInventario: ['inventario', 'access'],
        accessVentas: ['ventas', 'access'],
    })

    return <Sidebar {...props} navigate={useNavigate()} {...permisos} />
}