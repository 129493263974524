import React, { Component } from "react";
import { Row, Col, Typography, Space, Radio, Spin, Form, Divider, Input } from "antd";

import axios from "axios";

import User from '../../../Hooks/Logged'

const { Title, Text } = Typography;

export default class StepDirecciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            direccion: {},
            loading: false,
            direcciones: {
                data: [],
            },
            disabled: false
        }

    }

    formRef = React.createRef();
    static contextType = User;

    componentDidMount() {

        const user = this.context

        console.log('usuario', user)

        if (Object.keys(this?.props?.address ?? {}).length > 0)
            this.formRef.current.submit()

        if (user.cliente_id)
            this.getDirecciones()
    }

    handleFieldsChange = (_, allFields) => {
        // Verificar si todos los campos requeridos están llenos
        const isFilled = allFields?.every((field) => field.errors.length == 0);
        //this.props.setDisabled(!isFilled);
    };

    getDirecciones = () => {
        const user = this.context;
        this.setState({ loading: true })
        axios.get('/address', {
            params: {
                cliente_id: user.cliente_id
            }
        })
            .then(({ data }) => {

                console.log("data direcciones", data)

                this.setState({
                    direcciones: {
                        ...data,
                    }
                })
            })
            .catch(res => {
                console.log("res", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    onValuesChange = (changedValues, allValues) => {
        this.setState({ address: allValues })
        this.props.updateAdressData(allValues)
    }

    /**
     *
     *
     * @memberof StepDireccion
     * @method selectAddress
     * @description Al seleccionar una direccion previa o nueva se actualiza el props y el formulario
     */
    selectAddress = ({ target }) => {

        if (target.value != null) {
            let address = this.state.direcciones.data.find(d => d._id == target.value)
            this.onValuesChange({}, address)
            this.formRef.current.setFieldsValue(address)
            this.setState({ disabled: true })
        }
        else {
            this.onValuesChange({}, {})
            this.cleanForm()
            this.setState({ disabled: false })

        }

    }

    /**
     *
     *
     * @memberof StepDireccion
     * @method cleanForm
     * @description Limpia el formulario para ingresar una nueva direccion
     */
    cleanForm = () => {
        const emptyAddress = {
            alias: '',
            direccion: '',
            codigo_postal: '',
            ciudad: '',
            estado: '',
            pais: '',
            notas: '',
            especificaciones: '',
        };
        this.formRef.current.setFieldsValue(emptyAddress);
    }

    render() {
        return (
            <>
                {this.state.direcciones.data.length > 0 ?
                    <Spin spinning={this.state.loading}> <Row gutter={[16, 16]} justify={'start'}>
                        <Col span={24}><Title level={5}>Elija a que dirección enviar</Title></Col>
                        <Radio.Group name="direccion_selected" className='width-100' onChange={this.selectAddress} >
                            {this.state.direcciones.data?.map(item => {
                                return <Col span={24} className='width-100'>
                                    <Radio value={item._id} rootClassName='radio-card-item width-100'>
                                        <Space direction='vertical' size={5} align="start">
                                            <Text ellipsis>{item.direccion}</Text>
                                            <Text strong>C.P. {item.codigo_postal}</Text>
                                        </Space>
                                    </Radio>
                                </Col>
                            })}
                            <Radio value={null} rootClassName='radio-card-item width-100'>+ Agregar nueva dirección</Radio>
                        </Radio.Group>

                    </Row ></Spin>
                    : null
                }
                <Form
                    layout={"vertical"}
                    ref={this.formRef}
                    className='mt-1'
                    initialValues={this.props.address}
                    onValuesChange={this.onValuesChange}
                    onFieldsChange={this.handleFieldsChange}
                    validateTrigger="onChange"
                >
                    <Spin spinning={this.state.loading}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Form.Item
                                    label="Alias"
                                    name="alias"
                                    rules={[{
                                        required: true,
                                        message: "Ingrese un alias para su dirección"
                                    }]}
                                >
                                    <Input placeholder="Alias" disabled={this.state.disabled}></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Código Postal"
                                    name='codigo_postal'
                                    rules={[{
                                        required: true,
                                        message: "Introduzca su código postal"
                                    }]}
                                >
                                    <Input placeholder="Código Postal" disabled={this.state.disabled} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Dirección"
                                    name='direccion'
                                    rules={[{
                                        required: true,
                                        message: "Introduzca su dirección"
                                    }]}
                                >
                                    <Input placeholder="Dirección" disabled={this.state.disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Ciudad"
                                    name='ciudad'
                                    rules={[{
                                        required: true,
                                        message: "Introduzca su ciudad"
                                    }]}
                                >
                                    <Input placeholder="Ciudad" disabled={this.state.disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Entidad Federativa"
                                    name='estado'
                                    rules={[{
                                        required: true,
                                        message: "Introduzca su entidad federativa"
                                    }]}
                                >
                                    <Input placeholder="Entidad Federativa" disabled={this.state.disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="País"
                                    name='pais'
                                    rules={[{
                                        required: true,
                                        message: "Introduzca su país"
                                    }]}
                                >
                                    <Input placeholder="Entidad Federativa" disabled={this.state.disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Especificaciones"
                                    name='especificaciones'
                                >
                                    <Input.TextArea placeholder="Especificaciones" rows={2} disabled={this.state.disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Notas"
                                    name='notas'
                                >
                                    <Input.TextArea placeholder="Notas" rows={4} disabled={this.state.disabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </>
        )
    }
}