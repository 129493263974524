import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin } from 'antd';

import axios from "axios"
import { getResponseError } from "../../Utils";
import { ImagesUploader } from "../../Widgets/Uploaders";

const { Title } = Typography;
const { Option } = Select;


class ModalCatalogos extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.catalogo_id !== undefined) {
            this.getCatalogo()
        }
    }

    /**
     * @memberof ModalCatalogos
     * @method getCatalogo
     * @description Obtiene una Catalogo de la DB
     *
     */
    getCatalogo = () => {
        this.setState({ loading: true })
        axios.get('/catalogo/' + this.props.catalogo_id).then(response => {
            let catalogo = response.data;
            this.formRef.current.setFieldsValue({
                nombre: catalogo.nombre,
                descripcion: catalogo.descripcion,
                archivo: catalogo.archivo ? [{
                    uid: catalogo.archivo.file,
                    name: catalogo.archivo.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/catalogo/" + catalogo._id + "?archivo=true&Authorization=" + sessionStorage.getItem("token")
                }] : null
            })
        }).catch(error => {
            message.error('Error al obtener la categoría')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCatalogos
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            catalogo_id: this.props.catalogo_id,
            archivo: undefined
        })

        if (Array.isArray(values.archivo) && values.archivo.length > 0) {
            let archivo = values.archivo[0]
            if (!archivo.fromDB) formData.append('archivo', archivo, archivo.name)
        }

        if (this.props.catalogo_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    }

    /**
     * @memberof ModalCatalogos
     * @method add  
     * @description Añade una catalogo a la BD
     *
     */
    add = (values) => {
        axios.post('/catalogos', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Categoría creada!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al crear la categoría '))
            console.log(error)
        })
    }

    /**
     * @memberof ModalCatalogos
     * @method update
     * @description Actualiza la información de una Catalogo
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/catalogos', values,{
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Categoría actualizada!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error actualizando categoría'))
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-catalogo"
                id="form-catalogo"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" rows={4} />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                name="archivo"
                                label="Archivo"
                            >
                                <ImagesUploader
                                    maxCount={1}
                                    showUploadList={true}
                                    imageCrop={false}
                                    onRemove={({ name, fromDB }) => {
                                        if (fromDB) axios.put(`/catalogos`, {
                                            id: this.props.catalogo_id,
                                            delete_image: true
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, catalogo_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={catalogo_id ? "Editar Catálogo" : "Crear Catálogo"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-catalogo', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCatalogos {...props} />
    </Modal>

}