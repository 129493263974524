import { MenuOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
    Radio,
    Space
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import User from '../../../../Hooks/Logged';
import useSearch from '../../../../Hooks/useSearch';

import PageHeader from '../../../Widgets/PageHeader/PageHeader';

import '../../../../Styles/Modules/Admin/clientes.scss';
import ClientesList from './ClientesList';
import ClientesTabs from './ClientesTabs';

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class Clientes extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)

        this.state = {
            view: 'tabs'
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    render() {

        const { view } = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={"Clientes"}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'tabs' },
                            ]}
                            value={this.state.view}
                            onChange={(e) => this.setState({view: e.target.value})}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                
                {
                    view === 'tabs' ? <ClientesTabs/> : <ClientesList/>
                }

            </>
        )
    }
}

export default function Vista(props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)


    const navigate = useNavigate();
    const location = useLocation();


    return <Clientes {...props}
        user={user}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}