import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, 
    Layout, Space, message, List, Card, Collapse, Modal,
} from 'antd'
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

import User from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';

import FloatingButton from '../../../Widgets/Floating Button/FloatingButton'
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import { IconEye } from '../../../Widgets/Iconos'

import '../../../../Styles/Modules/Admin/clientes.scss'

import moment from 'moment';
import ModalClientes from './ModalClientes';
import { WarningFilled } from '@ant-design/icons';
import { getResponseError } from '../../../Utils';

const { Content } = Layout;
const { Text } = Typography
const { confirm } = Modal

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesTabs extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            clientes: {
                data: [],
                skip: 0,
                limit: 50,
                filters: [],
                total: 0,
            },
            sort: 1,
            panels: [0, 1, 2, 3, 4],
            seleccionados: {},
            modalCliente: false,
            search: this.props.search,
            first: true
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {

        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters });

        const self = this
        window.onscroll = function () {
            if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.clientes.skip < self.state.clientes.total) {
                self.getClientes({ skip: (parseInt(self.state.clientes.skip) + parseInt(self.state.clientes.limit)) })
            }
        }
    }

    componentWillUnmount() {
        window.onscroll = null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getClientes({ search: this.props.search, skip: 0 })
        }
    }

    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({
        skip = this.state.clientes.skip,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        panels = this.state.panels,
        sort = this.state.sort
    } = this.state.clientes, { } = this.state
    ) => {
        const params = { limit, skip }
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        let clearCheckbox = (skip === 0)

        this.setState({ loading: true, panels, clearCheckbox, sort })
        axios.get('/clientes', {
            params: {
                skip: this.state.first ? 0 : skip,
                limit: (this.state.first && skip > 0) ? skip : limit,
                panels,
                search: this.props.search,
                filters,
                group: "estatus",
                sort
            }
        })
            .then(({ data }) => {
                console.log('clientes=>', data)
                this.setState({
                    clientes: {
                        ...data,
                        data: {
                            0: (skip == 0) ? (data.data[0] ?? []) : this.state.clientes.data[0].concat(data.data[0] ?? []),
                            1: (skip == 0) ? (data.data[1] ?? []) : this.state.clientes.data[1].concat(data.data[1] ?? []),
                            2: (skip == 0) ? (data.data[2] ?? []) : this.state.clientes.data[2].concat(data.data[2] ?? []),
                            3: (skip == 0) ? (data.data[3] ?? []) : this.state.clientes.data[3].concat(data.data[3] ?? []),
                            4: (skip == 0) ? (data.data[4] ?? []) : this.state.clientes.data[4].concat(data.data[4] ?? []),
                        },
                        skip,
                        limit,
                        filters: filters
                    },
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }

    render() {

        const { clientes, loading } = this.state

        let estatus = [
            {
                _id: 0,
                name: 'perdido',
                label: "Desistío / Perdido"
            },
            {
                _id: 1,
                name: 'registrados',
                label: "Registrado"
            },
            {
                _id: 2,
                name: 'contactado',
                label: "Contactado"
            },
            {
                _id: 3,
                name: 'comprado',
                label: "Ya compró algo"
            },
            {
                _id: 4,
                name: 'pagado',
                label: "Pagado"
            }
        ]


        return (
            <>
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="clientes-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={e => this.getClientes({ skip: 0, panels: e })}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={label}
                                        className={`clientes-tab tab-${name}`}
                                    >
                                        <List
                                            loading={clientes.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Clientes" }}
                                            dataSource={this.state.clientes.data[_id] ?? []}
                                            renderItem={(cliente, index) => (
                                                <List.Item className="component-list-item" key={cliente._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">
                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 12]}>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis strong>{cliente.nombre} {cliente.apellidos}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{cliente.email}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{cliente.telefono ?? "-"}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{moment(cliente.createdAt).format("DD/MM/YYYY")}</Text>
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
                                                                            <Button 
                                                                                type='primary' 
                                                                                className='btn-primary' 
                                                                                icon={<IconEye />} 
                                                                                disabled={!this.props.canViewDetails}
                                                                                onClick={()=>this.props.navigate(`/admin/crm/clientes/${cliente._id}`)}
                                                                            />
                                                                            <ButtonEdit onClick={() => this.setState({ modalCliente: true, cliente_id: cliente._id })}
                                                                                disabled={!this.props.canEdit}
                                                                            />
                                                                            <ButtonDelete disabled={!this.props.canDelete} onConfirm={
                                                                                 () => confirm({
                                                                                    title: "¿Quieres eliminar este cliente?",
                                                                                    icon: <WarningFilled />,
                                                                                    content: "Todo lo relacionado a él será eliminado",
                                                                                    okText: "Eliminar",
                                                                                    cancelText: "Cancelar",
                                                                                    onOk: () => axios.delete('/clientes', { params: { cliente_id: cliente._id } })
                                                                                        .then((response) => {
                                                                                            message.success("Eliminado correctamente")
                                                                                            this.getClientes()
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            message.error(getResponseError(error.response, "Error al eliminar"));
                                                                                            this.getClientes()
                                                                                        }),
                                                                                    onCancel: () => { }
                                                                                })
                                                                            } />
                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>
                {this.props.canCreate && <FloatingButton title="Nuevo Cliente" disabled={!this.props.canCreate} onClick={() => this.setState({ modalCliente: true, cliente_id: null })} />}
                <ModalClientes
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        this.getClientes()
                    }}
                />
            </>
        )
    }
}

export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "detalles"]
    })


    console.log("permisos", permisos)

    const navigate = useNavigate();
    const location = useLocation();


    return <ClientesTabs {...props}
        user={user}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}