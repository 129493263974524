import { Col, Form, Input, Modal, Row, Select, Spin, Tag, message } from 'antd';
import axios from "axios";
import React, { Component } from "react";




/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            asesores: [],
            estados: [],
            paises: [],
            cliente: {
                direcciones: []
            }

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.cliente_id)
            this.getCliente()

    }

    formRef = React.createRef();


    /**
    * @method getCliente
    * @description Obtener al cliente
    */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.props.cliente_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data
                })
                this.setState({ cliente: data })
            }).catch(error => {
                message.error('Error al obtener el cliente')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method save
    * @description Añade un nuevo registro de cliente
    */
    save = (values) => {
        this.setState({ loading: true })
        axios.post('/clientes', values)
            .then(response => {
                message.success('Cliente creado!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(error.response?.data?.message ?? 'Error al crear el cliente')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method update
    * @description Actualiza un registro de cliente
    */
    update = (values) => {

        this.setState({ loading: true })
        axios.put('/clientes', values)
            .then(response => {
                message.success('Cliente actualizado!')
                this.props.onClose(true)
            }).catch(error => {
                console.log("error", error);
                message.error(error.response?.data?.message ?? 'Error actualizando el cliente')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            values.cliente_id = this.props.cliente_id
            this.update(values)
        } else {
            this.save(values)
        }
    }


    renderEstatus = (status) => {

        switch (status) {
            case 0:
                return <Tag color="#FF4D4F">Desistió / Perdido</Tag>
            case 1:
                return <Tag color="#FFE608">Registrado</Tag>
            case 2:
                return <Tag color="#5465FF">Contactado</Tag>
            case 3:
                return <Tag color="#F2802E">Ya compró algo</Tag>
            case 4:
                return <Tag color="#00CD98">Pagado</Tag>
            default:
                return <Tag color="#FFE608">Registrado</Tag>
        }
    }

    render() {

        let { cliente } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    className="mt-3"
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nombre es requerido',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'El campo apellidos es requerido',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Ingrese un correo valido "
                                    }, {
                                        required: true,
                                        message: 'El campo email es requerido',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>



                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="estatus"
                                label="Estatus"
                                rules={[{
                                    required: true,
                                    message: "Selecciona el estatus"
                                }]}
                            >
                                <Select options={[
                                    {
                                        value: 0,
                                        label: this.renderEstatus(0)
                                    },
                                    {
                                        value: 1,
                                        label: this.renderEstatus(1)
                                    },
                                    {
                                        value: 2,
                                        label: this.renderEstatus(2)
                                    },
                                    {
                                        value: 3,
                                        label: this.renderEstatus(3)
                                    },
                                    {
                                        value: 4,
                                        label: this.renderEstatus(4)
                                    }
                                ]}
                                    placeholder="Estatus"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cliente_id ? "Editar Cliente" : "Crear Cliente"}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
    >
        <ModalClientes  {...props} />
    </Modal>

}