import React from 'react'
import { Route, Routes } from "react-router-dom";

import Categorias from '../../Components/Admin/Categorias/Categorias';

/**
 * 
 * @export
 * @function RouterCategorias
 * @description Router for Categorias routes 
 */
function RouterCategorias(props) {
  return (
    <Routes>
      <Route path="" element={<Categorias {...props} />} />
    </Routes>
  )
}

export default RouterCategorias