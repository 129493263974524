import React from 'react';
import { Card, Row, Col, Avatar, Typography, Button, Space } from 'antd';
import { DeleteOutlined } from "@ant-design/icons"
const { Text, Paragraph } = Typography;



const renderImage = (id, images, model) => {
  if (images && images.length > 0)
    return `${process.env.REACT_APP_WEB_SERVICE}/${model}/${id}?imagen=${images[0]?.file}`
  return `${process.env.REACT_APP_WEB_SERVICE}/images/default.png`
}

/**
 *
 *
 * @param {*} { item, cantidad, onDelete }
 * @returns 
 */
function CartElement({ item, cantidad, onDelete }) {
  let { _id, variante_id, servicio_id, precio, producto_id } = item;
  console.log(item)
  let nombre = "product";
  let descripcion = "descripcion";
  let imagenes = [];
  let model = ""
  let id = "";
  if (producto_id) {
    model = "product";
    nombre = producto_id.nombre;
    descripcion = producto_id.descripcion;
    imagenes = producto_id.imagenes;
    id = producto_id._id;

  }
  if (servicio_id) {
    model = "service";
    nombre = servicio_id.nombre;
    descripcion = servicio_id.descripcion;
    imagenes = servicio_id.imagenes;
    id = servicio_id._id;
  }
  return (
    <Card className='width-100'>
      <Row gutter={[8, 0]} justify={"space-between"}>
        <Col span={4}>
          <Avatar shape='square' size={50}
            src={renderImage(id, imagenes, model)}
            alt={nombre} />
        </Col>
        <Col span={10}>
          <Space direction="vertical" size={"small"}>
            <Text strong>{nombre}</Text>
            <Text >{variante_id?.nombre}</Text>
          </Space>
          <Paragraph ellipsis className='text-gray'>{descripcion}</Paragraph>
        </Col>
        <Col span={4} className='center'>
          <Text type="secondary">{precio ? `$ ${precio}` : null}</Text>
        </Col>
        <Col span={4} className='center'>
          <Text type="secondary">{cantidad}</Text>
        </Col>
        <Col span={2} className='center'>
          <Button type="primary" size='small' danger icon={<DeleteOutlined />} onClick={() => onDelete(_id)}></Button>
        </Col>
      </Row>
    </Card>
  );
}

export default CartElement;