import React from 'react'
import { Route, Routes } from "react-router-dom";

import Ventas from '../../Components/Admin/Ventas/Ventas'
import DetalleVentas from '../../Components/Admin/Ventas/DetalleVentas';

/**
 * 
 * @export
 * @function RouterCategorias
 * @description Router for Categorias routes 
 */
function RouterVentas(props) {
  return (
    <Routes>
      <Route path="" element={<Ventas {...props} />} />
      <Route path="/:venta_id" element={<DetalleVentas  {...props}/>} />
    </Routes>
  )
}

export default RouterVentas