import { Button, Card, Col, Form, Input, Modal, Row, Select, Space, Spin, Tag, Typography, message } from 'antd';
import React, { Component } from "react";

import axios from "axios";

import { getResponseError } from "../../Utils";
import SelectClientes from '../../Widgets/Inputs/SelectClientes';
import SelectDirecciones from "../../Widgets/Inputs/SelectDirecciones";


const { Title, Text } = Typography;
const { Option } = Select;


class ModalVentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            direccion: null,
            status: 1 // Pendiente
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.venta_id !== undefined) {
            this.getVenta()
        }
    }

    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una Categoria de la DB
     *
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.props.venta_id).then(response => {
            console.log(response)
            let venta = response.data;
            this.setState({cliente_id: venta?.cliente_id, direccion: venta?.direccion, status: venta.status})
            this.formRef.current.setFieldsValue({
                ...venta,
                cliente_id: venta?.cliente_id ? ({
                    value: venta?.cliente_id?._id,
                    label: `${venta?.cliente_id?.nombre} ${venta?.cliente_id?.apellidos}`
                }) : null
            })
        }).catch(error => {
            message.error("Ocurrio un error cargando la información de la venta")
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCategorias
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        console.log(values)

        if(values.cliente_id?.value) values.cliente_id = values.cliente_id?.value

        if (this.props.venta_id) {
            this.update(values)
        } else {
            this.add(values)
        }

    }

    renderStatus = (status) => {
        switch (status) {
            case 1:
                return <Tag color="#FFE608">Pendiente</Tag>

            case 2:
                return <Tag color="#5465FF">Completo</Tag>

            case 3:
                return <Tag color="#F2802E">Cancelado</Tag>

            case 4:
                return <Tag color="#00CD98">Reembolsado</Tag>

            default:
                return <Tag color="#FFE608">Pendiente</Tag>

        }

    }

    /**
     * @memberof ModalCategorias
     * @method add
     * @description Añade una categoria a la BD
     *
     */
    add = (values) => {
        axios.post('/ventas', {
            ...values,
            primary_color: values.primary_color.toHexString(),
            secondary_color: values.secondary_color ? values.secondary_color.toHexString() : null
        }).then(response => {
            message.success('Venta creada!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, "Ocurrio un error al crear la venta"))
            console.log(error)
        })
    }

    /**
     * @memberof ModalCategorias
     * @method update
     * @description Actualiza la información de una Categoria
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        console.log(values)
        axios.put('/ventas', {
            ...values,
            id: this.props.venta_id
        }).then(response => {
            message.success('Venta actualizada!')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, "Ocurrio un error al actualizar la venta"))
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const { venta_id } = this.props
        const { direccion, status } = this.state

        return (
            <Form
                layout="vertical"
                name="form-venta"
                id="form-venta"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                name="status"
                                label="Estatus"
                                rules={[{
                                    required: true,
                                    message: "Selecciona el estatus"
                                }]}
                            >
                                <Select disabled={status !== 1} options={[
                                    {
                                        value: 1,
                                        label: this.renderStatus(1)
                                    },
                                    {
                                        value: 2,
                                        label: this.renderStatus(2)
                                    },
                                    {
                                        value: 3,
                                        label: this.renderStatus(3)
                                    },
                                    {
                                        value: 4,
                                        label: this.renderStatus(4)
                                    }
                                ]}
                                    placeholder="Estatus"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="order_id"
                                label="ID de la Orden"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="tracking_number"
                                label="Codigo de Rastreo"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item 
                                name="cliente_id"
                                label="Cliente"
                                rules={[{
                                    required: true,
                                    message: 'Seleccione el cliente'
                                }]}
                            >
                                <SelectClientes
                                    onSelect={(cliente_id) => this.setState({cliente_id, direccion: null})}
                                />
                            </Form.Item>
                        </Col>
                        {
                            venta_id && direccion && (
                                <Col xs={24}>
                                    <Card size="small" title="Dirección" extra={<Button danger type="link" disabled={status !== 1} onClick={() => this.setState({direccion: null})}>Remover</Button>}>
                                        <Space direction='vertical'>
                                            <Text>{direccion?.direccion ?? ""}, {direccion?.codigo_postal ?? ""}</Text>
                                            <Text>{direccion?.ciudad ?? ""} {direccion?.estado ?? ""}, {direccion?.pais ?? ""}</Text>
                                        </Space>
                                    </Card>
                                </Col>
                            )
                        }
                        {
                            !direccion && (
                                <Col xs={24}>
                                    <Form.Item 
                                        name="direccion_id"
                                        label="Dirección"
                                        rules={[{
                                            required: true,
                                            message: 'Seleccione la dirección'
                                        }]}
                                    >
                                        <SelectDirecciones disabled={!this.state.cliente_id} params={{cliente_id: this.state.cliente_id}}/>
                                    </Form.Item>
                                </Col>                                
                            )
                        }
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={venta_id ? "Editar Venta" : "Crear Venta"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-venta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVentas {...props} />
    </Modal>

}