import React from 'react';
import { DownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import PreviewWrapper from '../PreviewWrapper';
// import './style.less';

const { Title } = Typography
const ButtonPreview = ({ size, disabled }) => (
  [
    <PreviewWrapper id="Button" title="Button">
      <div className="components buttons">
        <div className="component-row">
          <div className="component-col">
            <Button
              className='ant-btn-primary-greenjenny'
              type="primary" size={size} disabled={disabled}>Primary</Button>
          </div>
          <div className="component-col">
            <Button size={size} disabled={disabled}>Normal</Button>
          </div>
          <div className="component-col">
            <Button type="dashed" size={size} disabled={disabled}>Dashed</Button>
          </div>
          <div className="component-col">
            <Button type="danger" size={size} disabled={disabled}>Danger</Button>
          </div>
          <div className="component-col">
            <Button type="link" size={size} disabled={disabled}>Link</Button>
          </div>
        </div>
        <div className="component-row ghost">
          <div className="component-col">
            <Button type="primary" ghost size={size} disabled={disabled}>Primary</Button>
          </div>
          <div className="component-col">
            <Button ghost size={size} disabled={disabled}>Default</Button>
          </div>
          <div className="component-col">
            <Button ghost type="dashed" size={size} disabled={disabled}>Dashed</Button>
          </div>
          <div className="component-col">
            <Button ghost type="danger" size={size} disabled={disabled}>Danger</Button>
          </div>
          <div className="component-col">
            <Button ghost type="link" size={size} disabled={disabled}>Link</Button>
          </div>
        </div>
        <div className="component-row">
          <div className="component-col">
            <Button type="primary" icon={<DownloadOutlined />} size={size} disabled={disabled} />
          </div>
          <div className="component-col">
            <Button type="primary" shape="circle" icon={<DownloadOutlined />} size={size} disabled={disabled} />
          </div>
          <div className="component-col">
            <Button type="primary" shape="round" icon={<DownloadOutlined />} size={size} disabled={disabled}>Download</Button>
          </div>
          <div className="component-col">
            <Button type="primary" icon={<DownloadOutlined />} size={size} disabled={disabled}>Download</Button>
          </div>
        </div>
        <div className="component-row">
          <Button.Group size={size}>
            <Button type="primary" disabled={disabled}>
              <LeftOutlined />
              Backward
            </Button>
            <Button type="primary" disabled={disabled}>
              Forward
              <RightOutlined disabled={disabled} />
            </Button>
          </Button.Group>
        </div>
      </div>
    </PreviewWrapper>,
    <PreviewWrapper id="Button" title="Button Colors">
      {[
        "blue",
        "purple",
        "cyan",
        "green",
        "magenta",
        "pink",
        "red",
        "orange",
        "yellow",
        "volcano",
        "geekblue",
        "lime",
        "gold"
      ].map(color => <div className="components buttons">

        <div className="component-row" style={{ marginTop: "1em" }}>
          <Title level={4}>Color {color.replace(/^./, color[0].toUpperCase())}</Title>
        </div>
        <div className="component-row">

          <div className="component-col">
            <Button
              className={'ant-btn-primary-'+color}
              type="primary" size={size} disabled={false}>Primary</Button>
          </div>
          <div className="component-col">
            <Button
              // className='ant-btn-default-red'
              className={'ant-btn-default-'+color}//</div>='ant-btn-default-red'
              size={size} disabled={false}>Normal</Button>
          </div>
          <div className="component-col">
            <Button
              // className='ant-btn-dashed-red'
              className={'ant-btn-dashed-'+color}
              // className='ant-btn-dashed-red'
              type="dashed" size={size} disabled={false}>Dashed</Button>
          </div>
        </div>
        <div className="component-row">
          <div className="component-col">
            <Button
              // className='ant-btn-primary-red'
              className={'ant-btn-primary-'+color}
              // className='ant-btn-primary-red'
              type="primary" size={size} disabled={true}>Primary</Button>
          </div>
          <div className="component-col">
            <Button
              // className='ant-btn-default-red'
              className={'ant-btn-default-'+color}
              // className='ant-btn-default-red'
              size={size} disabled={true}>Normal</Button>
          </div>
          <div className="component-col">
            <Button
              // className='ant-btn-dashed-red'
              className={'ant-btn-dashed-'+color}
              type="dashed" size={size} disabled={true}>Dashed</Button>
          </div>

        </div>
        <div className="component-row ghost">
          <div className="component-col">
            <Button
              type="primary"
              ghost size={size} disabled={false}
              // className='ant-btn-ghost-red'
              className={'ant-btn-ghost-'+color}
            >Primary</Button>
          </div>
          <div className="component-col">
            <Button
              // className='ant-btn-ghost-red'
              className={'ant-btn-ghost-'+color}
              ghost size={size} disabled={disabled}>Default</Button>
          </div>
          <div className="component-col">
            <Button
              className={'ant-btn-ghost-'+color}
              ghost
              type="dashed"
              size={size} disabled={disabled}
            >Dashed</Button>
          </div>
          <div className="component-col">
            <Button className={'ant-btn-ghost-'+color} ghost type="danger" size={size} disabled={disabled}>Danger</Button>
          </div>
        </div>
      </div>)}
    </PreviewWrapper>
  ]
);

export default ButtonPreview;