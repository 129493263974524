import {
    Button,
    Card,
    Col,
    Layout,
    List,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Typography,
    message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import User from '../../../../Hooks/Logged';
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';


//import ModalDetalle from "../Modales/ModalClienteDetalle"

import { getResponseError } from '../../../Utils';
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import ModalProductos from './../../Productos/ModalProductos';
import { LinkOutlined } from '@ant-design/icons';
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';
import ModalVincular from '../ModalVincular';


const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista donde se listan todos los Productos
 */
class Productos extends Component {



    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            productos: {
                data: [{}],
                page: 1,
                limit: 10
            }
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()

    }


    get = () => {
        this.setState({loading: true})
        axios.get('/productos', {
            params: {
                catalogo_id: this.props.catalogo_id
            }
        }).then(({data}) => {
            this.setState({productos: data})
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener los productos"));
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {
        const { productos, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col xs={24} className="">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Productos sin registros" }}
                            dataSource={productos.data}
                            pagination={{
                                current: productos.page,
                                pageSize: productos.limit,
                                total: productos.total,
                                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                position: 'bottom',
                                className: "flex-left ml-1",
                                onChange: (page, limit) => this.get({ page, limit })
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{item.modelo}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{item.marca}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text ellipsis>{item.sku}</Text>
                                            </Col>
                                            <Col xs={4} className="flex-right">
                                                <Space>
                                                    <ButtonEdit
                                                        //disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({ modalProducto: true, producto_id: item._id })}
                                                    />
                                                    <Popconfirm
                                                        title="¿Quieres desvincular este producto?"
                                                        okText="Sí"
                                                        cancelText="No"
                                                        onConfirm={() => {
                                                            axios.put('/catalogos/desvincular', {
                                                                producto_id: item._id,
                                                                catalogo_id: this.props.catalogo_id
                                                            }).then(response => {
                                                                message.success('Producto Desvinculado')
                                                            }).catch(error => {
                                                                message.error(getResponseError(error.response, 'Error al desvincular producto.'))
                                                                console.log(error)
                                                            }).finally(() => {
                                                                this.get()
                                                                this.props.getData()
                                                            })
                                                        }}
                                                    >
                                                        <Button 
                                                            type='primary' 
                                                            danger 
                                                            icon={<LinkOutlined />} 
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

                <FloatingButton icon={<LinkOutlined 
                    style={{
                        fontSize: 36,
                        color: 'white'
                    }}
                />} title="Vincular Producto"  onClick={() => this.setState({ modalVincular: true, catalogo_id: this.props.catalogo_id })} />

                <ModalProductos
                    visible={this.state.modalProducto}
                    producto_id={this.state.producto_id}
                    onClose={() => {
                        this.setState({ modalProducto: false, producto_id: undefined })
                        this.get()
                        this.props.getData()
                    }}
                />

                <ModalVincular
                    visible={this.state.modalVincular}
                    catalogo_id={this.state.catalogo_id}
                    onClose={() => {
                        this.setState({modalVincular: false})
                        this.get()
                        this.props.getData()
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["productos", "create"],
        canEdit: ["productos", "edit"],
        canDelete: ["productos", "delete"],
        canViewDetails: ["productos", "cliente-detalles", "access"],
        canAssignAsesor: ["productos", "assign-asesor"]
    })

    const navigate = useNavigate();


    return <Productos {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       