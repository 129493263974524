
import React, { Component } from 'react'
import { Form, Input, Button, Row, Col, message, } from 'antd';

export default class StepPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    payment = () => {
        message.success('payment made successfully',this.props)
        this.props.updateQuotationData('payment', { monto: 0 })
        this.props.setDisabled(false)

        this.props.sendQuote()
    }
    render() {
        return (
            <Form
                layout={"vertical"}
                onFinish={this.payment}
            >
                <Row justify="center" className="center" gutter={[16, 8]}>
                    <Col span={24}>
                        <Form.Item
                            name="card_number"
                            label="Card Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Card Number es requerido',
                                },
                            ]}
                        >
                            <Input maxLength={16} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="expiration_date"
                            label="Expiration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Expiration es requerido',
                                },
                            ]}
                        >
                            <Input maxLength={5} placeholder='MM/DD' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="cvv"
                            label="Security Code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Security Code es requerido',
                                },
                            ]}
                        >
                            <Input type='password' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button type='primary' block style={{ background: 'green' }} loading={this.state.loading} htmlType="submit">Pay</Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}