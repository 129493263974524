import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Spin } from 'antd';
import '../../Styles/Global/auth.scss';

import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
class Recovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.put("/password/recovery", { email: values.email })
			.then(res => {
				Modal.success({
					title: 'Restablecer Contraseña',
					content: 'El correo se ha enviado'
				})
			})
			.catch(res => {
				console.log(res)
				Modal.warning({
					title: 'Error',
					content: 'No se pudo enviar el correo. Revise que este correcto.'
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})
	}



	render() {
		return (
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<div>
							<img width="400" src="/img/logo.svg" alt="Sneacare" />
						</div>
					</Row>
				</Col>
				<Col span={14}>
					<Spin spinning={this.state.loading}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
								<Title level={3} className="h3-title">Restablecer Contraseña</Title>
								<div className="mb-3">Ingrese el email y te enviaremos un link para restablecerlo</div>
								<Form.Item className='input-name' name="email" label="Email"
									rules={[
										{ required: true, message: "Ingrese email" }
									]}>
									<Input placeholder="example@gmail.com" size="large" />
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit" block size="large">Enviarme el link</Button>
								</Form.Item>
							</Form>
						</div>
					</Spin>
				</Col>
			</Row>
		)
	}
}

export default function View(props) {

	return <Recovery {...props} />
}