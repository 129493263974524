import {
    Button,
    Card,
    Col,
    Divider,
    Image,
    Layout,
    List,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Typography,
    message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import User from '../../../../Hooks/Logged';
import usePermissions from '../../../../Hooks/usePermissions';
import useSearch from '../../../../Hooks/useSearch';


//import ModalDetalle from "../Modales/ModalClienteDetalle"

import { getResponseError } from '../../../Utils';
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import { LinkOutlined } from '@ant-design/icons';
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';
import ModalDetalle from '../ModalDetalle';


const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class ProductosVentas
 * @extends {Component}
 * @description Vista donde se listan todos los ProductosVentas
 */
class ProductosVentas extends Component {



    /**
     *Creates an instance of ProductosVentas.
     * @param {*} props
     * @memberof ProductosVentas
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            detalles: {
                data: [{}],
                page: 1,
                limit: 10
            }
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()

    }


    get = () => {
        this.setState({loading: true})
        axios.get('/ventas/detalles', {
            params: {
                venta_id: this.props.venta_id
            }
        }).then(({data}) => {
            this.setState({detalles: data})
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener los detalles"));
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {
        const { detalles, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]} className='venta-detalles'>
                    <Col xs={24} className="">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Venta sin detalles" }}
                            dataSource={detalles.data}
                            pagination={{
                                current: detalles.page,
                                pageSize: detalles.limit,
                                total: detalles.total,
                                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                position: 'bottom',
                                className: "flex-left ml-1",
                                onChange: (page, limit) => this.get({ page, limit })
                            }}
                            header={
                                <Row align="middle">
                                    <Col flex="auto">
                                        <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                            <Col span={14} >
                                                <Text strong ellipsis>Información del Producto Cliente</Text>
                                            </Col>
                                            <Col span={9} >
                                                <Text strong ellipsis>Producto Seleccionado</Text>
                                            </Col>
                                            <Col span={1} className="center" >
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item detalle">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]} align={"middle"}>
                                            <Col span={14}>
                                                <Row gutter={[12, 12]} wrap={false}>
                                                    <Col flex="none">
                                                        <Image
                                                            placeholder
                                                            className='detalle-img'
                                                            {...(item.imagenes?.length > 0 && {src: axios.defaults.baseURL + "/ventas/detalle/" + item._id + "?imagen=" + item.imagenes[0].file + "&Authorization=" + sessionStorage.getItem("token")})}
                                                        />
                                                    </Col>
                                                    <Col flex="auto" className='detalle-info'>
                                                        <Space className='width-100' direction='vertical' size={0}>
                                                            <Text strong className='detalle-name'>{item.nombre ?? "--"} / {item.modelo ?? "--"}</Text>
                                                            <Text className='detalle-data'>{item.cantidad}</Text>
                                                            <Text className='detalle-data'></Text>
                                                            <Text className='detalle-data'>{item.precio_venta}</Text>
                                                        </Space>
                                                    </Col>
                                                    <Col flex="none">
                                                        <Divider type='vertical' style={{height: '100%'}}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={9}>
                                                <Row gutter={[12, 12]} wrap={false}>
                                                    {
                                                        item.producto_id && (
                                                            <>
                                                                <Col flex="none">
                                                                    <Image
                                                                        placeholder
                                                                        className='detalle-img'
                                                                        {...(item.producto_id.imagenes?.length > 0 && {src: axios.defaults.baseURL + "/productos/" + item.producto_id._id + "?imagen=" + item.producto_id.imagenes[0].file + "&Authorization=" + sessionStorage.getItem("token")})}
                                                                    />
                                                                </Col>
                                                                <Col flex="auto" className='detalle-info'>
                                                                    <Space className='width-100' direction='vertical' size={0}>
                                                                        <Text strong className='detalle-name'>{item.producto_id.nombre ?? "--"} / {item.producto_id.modelo ?? "--"}</Text>
                                                                        <Text className='detalle-data'>{item.producto_id.sku} / {item.producto_id.sku_variante}</Text>
                                                                        <Text className='detalle-data'>{item.precio_venta}</Text>
                                                                    </Space>
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                </Row>
                                            </Col>
                                            <Col span={1} className='center'>
                                                <Space direction='vertical'>
                                                    <ButtonEdit
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => this.setState({modalDetalle: true, detalle_id: item._id, cantidad: item.cantidad})}
                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.canDelete}
                                                        onConfirm={() => {
                                                            axios.delete('/ventas/detalles', {
                                                                params: {detalle_id: item._id}
                                                            }).then(() => {
                                                                message.success("Detalle eliminado")
                                                            }).catch((error) => {
                                                                message.error("Error al eliminar el detalle")
                                                            }).finally(() => {
                                                                this.get()
                                                                this.props.getData()
                                                            })
                                                        }}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

                {
                    this.props.canCreate && <FloatingButton title="Agregar Detalle"  onClick={() => this.setState({ modalDetalle: true })} />
                }

                <ModalDetalle
                    visible={this.state.modalDetalle}
                    venta_id={this.props.venta_id}
                    detalle_id={this.state.detalle_id}
                    cantidad={this.state.cantidad}
                    onClose={() => {
                        this.setState({modalDetalle: false, cantidad: undefined, detalle_id: undefined})
                        this.get()
                        this.props.getData()
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "productos-ventas", "create"],
        canEdit: ["ventas", "productos-ventas", "edit"],
        canDelete: ["ventas", "productos-ventas", "delete"],
    })

    const navigate = useNavigate();


    return <ProductosVentas {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       