import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Tag } from 'antd'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

// import ModalTransacciones from './ModalTransacciones'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import CustomAvatar from '../../Widgets/Avatar/Avatar';

const { Content } = Layout;
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista donde se listan todos los transacciones
 */
class Transacciones extends Component {
    static contextType = Logged;

    /**
     *Creates an instance of Transacciones.
     * @param {*} props
     * @memberof Transacciones
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            transacciones: {
                data: [{}, {}, {}, {}],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            }
        }
    }

    componentDidMount() {

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.get()
        }
    }

    /**
    *
    * @memberof Transacciones
    * @method get
    * @description Obtiene la lista de transacciones
    */
    get = async ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.props.search,
    } = this.state.transacciones) => {

        this.setState({ loading: true })
        axios.get('/transacciones', {
            params: {
                limit,
                page,
                search
            }
        })
        .then(({ data }) => {
            this.setState({ transacciones: data })
        })
        .catch(res => {
            console.log("res", res);
            message.error(res.response?.data?.message ?? "Error" )
        })
        .finally(() => this.setState({ loading: false }))
    }


    delete = () => {

    }

    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Transaccions"
                    >

                    </PageHeader>

                    <Content className="transacciones-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Transactions sin registros" }}
                            dataSource={this.state.transacciones.data}
                            pagination={{
                                current: this.state.transacciones.page,
                                pageSize: this.state.transacciones.limit,
                                total: this.state.transacciones.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.get({ page, limit })

                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} md={3}>
                                                <Text strong ellipsis>{"{{Date}}"}</Text>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Text ellipsis>{"{{Concept}}"}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>{"{{Account}}"}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>{"{{Other}}"}</Text>
                                            </Col>
                                            <Col xs={12} md={2} className="center">
                                                <CustomAvatar 
                                                    url={{
                                                        url: `${axios.defaults.baseURL}/cuentas/${item._id}`,
                                                        logo: item.logo?.file
                                                    }} color={item.color} 
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>{"{{Amount}}"}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="flex-right">
                                                <Space>
                                                    <ButtonView 
                                                        
                                                        onClick={() => this.setState({modalDetalleVisible: true, transaccion_id: item._id})} 
                                                    />
                                                    <ButtonEdit
                                                        
                                                        onClick={() => this.setState({modalVisible: true, transaccion_id: item._id})} 
                                                    />
                                                    <ButtonDelete
                                                        
                                                        onClick={() => this.delete(item._id)} 
                                                    />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)

    return <Transacciones {...props} navigate={useNavigate()}/>
}