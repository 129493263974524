import React from 'react';
import { Route, Routes } from "react-router-dom";

import Clientes from '../../Components/Admin/CRM/Clientes/Clientes';
import DetalleCliente from '../../Components/Admin/CRM/Clientes/DetalleCliente';
import Dashboard from '../../Components/Admin/CRM/Dashboard';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCRM(props) {

    return (
        <Routes>
            <Route path="" element={<Dashboard  {...props}  />} />
            <Route path="clientes" element={<Clientes  {...props}  />} />
            <Route path="clientes/:cliente_id" element={<DetalleCliente  {...props}/>} />
        </Routes>
    )
}

export default RouterCRM;