import {
    Button,
    Card,
    Col,
    Layout,
    List,
    Modal,
    Row,
    Select,
    Space,
    Tag,
    Typography,
    message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import User from '../../../../../Hooks/Logged';
import usePermissions from '../../../../../Hooks/usePermissions';
import useSearch from '../../../../../Hooks/useSearch';
import { IconEye } from '../../../../Widgets/Iconos';


//import ModalDetalle from "../Modales/ModalClienteDetalle"


import moment from 'moment';
import '../../../../../Styles/Modules/Admin/ventas.scss';
import { getResponseError, moneyFormat } from '../../../../Utils';
import ModalVentas from '../../../Ventas/ModalVentas';
import { ButtonDelete, ButtonEdit } from '../../../../Widgets/Buttons';


const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class Compras
 * @extends {Component}
 * @description Vista donde se listan todos los Compras
 */
class Compras extends Component {



    /**
     *Creates an instance of Compras.
     * @param {*} props
     * @memberof Compras
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            ventas: {
                data: [{}],
                page: 1,
                limit: 10
            }
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()

    }


    get = () => {
        this.setState({loading: true})
        axios.get('/ventas', {
            params: {
                cliente_id: this.props.cliente_id
            }
        }).then(({data}) => {
            this.setState({ventas: data})
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener las compras"));
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    renderEstatus = (status) => {

        switch (status) {
            case 1:
                return <Tag color="#FFE608">Pendiente</Tag>
            case 2:
                return <Tag color="#5465FF">Completo</Tag>
            case 3:
                return <Tag color="#F2802E">Cancelado</Tag>
            case 4:
                return <Tag color="#00CD98">Reembolsado</Tag>
            default:
                return <Tag color="#FFE608">Pendiente</Tag>
        }
    }

    render() {
        const { ventas, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col xs={24} className="">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Compras sin registros" }}
                            dataSource={ventas.data}
                            pagination={{
                                current: ventas.page,
                                pageSize: ventas.limit,
                                total: ventas.total,
                                pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                position: 'bottom',
                                className: "flex-left ml-1",
                                onChange: (page, limit) => this.get({ page, limit })
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row align="middle">
                                            <Col flex="auto">
                                                <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                    <Col xs={3} className="center" >
                                                        <Text ellipsis >{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                                                    </Col>
                                                    <Col xs={5} className="center" >
                                                        <Text ellipsis >{item.order_id}</Text>
                                                    </Col>
                                                    <Col xs={5} className="center" >
                                                        <Text ellipsis >{item.tracking_number}</Text>
                                                    </Col>
                                                    <Col xs={4} className="center" >
                                                        {this.renderEstatus(item.status)}
                                                    </Col>
                                                    <Col xs={4} className="center" >
                                                        <Text ellipsis >{moneyFormat(item.subtotal)}</Text>
                                                    </Col>
                                                    <Col xs={3} className="center" >
                                                        <Space wrap>
                                                            <ButtonEdit
                                                                disabled={!this.props.canEdit}
                                                                onClick={() => this.setState({ modalVenta: true, venta_id: item._id })} />
                                                            <ButtonDelete
                                                                disabled={!this.props.canDelete}
                                                                onConfirm={() => {
                                                                    axios.delete('/ventas', { params: { id: item._id } })
                                                                        .then((response) => {
                                                                            message.success("Compra eliminada")
                                                                        })
                                                                        .catch((error) => {
                                                                            message.error(getResponseError(error.response, "Error al eliminar la compra"));
                                                                        })
                                                                        .finally(() => {
                                                                            this.get()
                                                                            this.props.getData()
                                                                        })
                                                                }} />

                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

                <ModalVentas
                    visible={this.state.modalVenta}
                    venta_id={this.state.venta_id}
                    onClose={() => {
                        this.setState({ modalVenta: false, venta_id: undefined })
                        this.get()
                        this.props.getData()
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["clientes", "compras", "edit"],
        canDelete: ["clientes", "compras", "delete"]
    })

    const navigate = useNavigate();


    return <Compras {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       